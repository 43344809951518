/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    strong: "strong",
    a: "a",
    h2: "h2",
    div: "div",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The consent components uses a React Context to provide the consent components with configuration."), "\n", React.createElement(_components.p, null, "At a minimum, you need to wrap the Signatu compontnts in a ", React.createElement(_components.code, null, "<SignatuContext>"), " with\na valid ", React.createElement(_components.code, null, "accessToken"), "."), "\n", React.createElement(_components.p, null, "You can provide ", React.createElement(_components.code, null, "consentTarget"), " in the context. If you do not , you need to provide ", React.createElement(_components.code, null, "subject"), ", ", React.createElement(_components.code, null, "target"), " and other consent target parameters as ", React.createElement(_components.code, null, "props"), " to the individual components. You can also\noverride the ", React.createElement(_components.code, null, "consentTarget"), " even if set by the context."), "\n", React.createElement(_components.p, null, "We suggest using the ", React.createElement(_components.code, null, "<SignatuProvider>"), " component to provide the context."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "IMPORTANT"), " - make sure you understand the ", React.createElement(_components.a, {
    href: "/docs/api/v1/security"
  }, "security implications"), " of allowing API access tokens to your front-ends / apps."), "\n", React.createElement(_components.h2, {
    id: "signatuprovider",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signatuprovider",
    "aria-label": "signatuprovider permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SignatuProvider"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { SignatuProvider } from '@signatu/sdk'\n\nconst accessToken: 'MY_ACCESS_TOKEN'\n\nconst consentTarget = {\n    subject: 'subject',\n    target: 'https://consent/target',\n    issuer: 'https://mycomponany',\n}\n\nconst MyComponent = (props) => (\n    <SignatuProvider accessToken={accessToken} consentTarget={consentTarget}>\n        <ConsentButtons />\n        <ConsentButtons target={'anotherTarget'} />\n    </SignatuProvider>\n)\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
